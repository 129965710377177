// extracted by mini-css-extract-plugin
export var column = "ProjectWizardNavigation__column__VlY2t";
export var contentWrapper = "ProjectWizardNavigation__contentWrapper__QxYT8";
export var errorMessage = "ProjectWizardNavigation__errorMessage__qnm83";
export var flex = "ProjectWizardNavigation__flex__Mot7g";
export var flexColumn = "ProjectWizardNavigation__flexColumn__wV8xp";
export var gap1 = "ProjectWizardNavigation__gap1__F1O0e";
export var gap2 = "ProjectWizardNavigation__gap2__Jr0AF";
export var gap3 = "ProjectWizardNavigation__gap3__HOxK1";
export var gap4 = "ProjectWizardNavigation__gap4__dBkpd";
export var gap5 = "ProjectWizardNavigation__gap5__RtJzc";
export var nextButton = "ProjectWizardNavigation__nextButton__iGmaj";
export var nextButtonContainer = "ProjectWizardNavigation__nextButtonContainer__oHVLB";
export var row = "ProjectWizardNavigation__row__TS2zj";
export var saveAndContinueButton = "ProjectWizardNavigation__saveAndContinueButton__TKGbe";
export var saveAndContinueContainer = "ProjectWizardNavigation__saveAndContinueContainer__RdCXn";
export var stepButtonsContainer = "ProjectWizardNavigation__stepButtonsContainer___HHxG";
export var stepLabel = "ProjectWizardNavigation__stepLabel__pLWks";
export var steps = "ProjectWizardNavigation__steps__w6Xd0";